import { mapGetters, mapActions } from 'vuex'

const moduleName = 'quotecommunications'
const moduleNameQuote = 'quotes'

export default {
  computed: {
    ...mapGetters(moduleName, {
      getEmailer: 'getEmailer',
      getParameters: 'getParameters'
    }),
    ...mapGetters(['licenceExpired']),
    ...mapGetters(moduleNameQuote, {
      currentSnapshot: 'currentSnapshot'
    })
  },
  methods: {
    ...mapActions(moduleName, { addEmailer: 'addEmailer', removeEmailer: 'removeEmailer' }),
    ...mapActions(moduleName, {
      addParameter: 'addParameter',
      addParameters: 'addParameters',
      setEmailerAttachments: 'setEmailerAttachments',
      removeParameter: 'removeParameter',
      removeParameters: 'removeParameters'
    }),
    ...mapActions(moduleNameQuote, { getStoreItem: 'getStoreItem' })
  }
}
