<template>
  <article class="tile is-child box detail-page-tile">
    <div class="is-flex"
      style="justify-content: space-between">
      <span class="icon is-small tooltip is-tooltip-left is-clickable mr-2"
        data-tooltip="maximize">
        <i class="mdi mdi-24px"
          :class="{ 'mdi-arrow-collapse-right' : !editMaximize, 'mdi-arrow-collapse-left' : editMaximize}"
          @click="toggleEditMaximize()" />
      </span>
    </div>
    <div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Template</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control is-expanded">
              <multiselect v-model="selectedTemplate"
                track-by="message"
                label="message"
                placeholder="Select Template"
                :options="templates"
                :loading="isTemplateLoading"
                :searchable="true"
                :allow-empty="true"
                :options-limit="templateFilter.pageSize"
                @select="selectTemplate" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr>

    <vue-email ref="emailer"
      v-model="emailer"
      :quote-image-attachments="selectedImages"
      @new-email="newEmailInput"
      @sent="sent"
      :asset-id="emailer.assetId"
      :reply-to-message-id="innerReplyToEmail.replyToMessageId"
      :to-emails="replyToSenderEmails"
      :cc-emails="replyToEmailCcs"
      :bcc-emails="replyToEmailBccs"
      :subject="innerReplyToEmailSubject"
      :message="innerReplyToEmailMessage"
      :is-show-cc-bcc="(!!replyToEmailCcs.length || !!replyToEmailBccs.length)" />

    <!-- <image-select v-if="emailer && isImageAttachActive"
      v-show="isImageAttachActive"
      v-model="selectedImages"
      :quote-id="emailer.assetId"
      :quote-ids="quoteIds" /> -->

    <quote-communication-email-modal :active.sync="isAttachmentModalActive"
      v-model="selectedImages"
      :show-icon="true"
      :attach-disabled="false"
      :cancel-disabled="false"
      :emailer="emailer"
      :selected-attachment-email-id="selectedAttachmentEmailId"
      :is-incoming="isIncoming"
      @ok="closeAttachModal()"
      @cancel="closeAttachModal()"
      @close="closeAttachModal()">
      <p slot="text-title">Attachment</p>
    </quote-communication-email-modal>

    <div :style="`width: 100%;`"
      class="mt-3 buttons-group is-flex is-justify-content-space-between">
      <button class="button is-primary tooltip"
        data-tooltip="Edit message template"
        @click="isEmailTemplateModalActive = true">
        <span class="icon">
          <i class="mdi mdi-pencil mdi-20px" />
        </span>
        <span>Edit Template</span>
      </button>
      <div class="buttons">
        <button class="button is-warning tooltip is-tooltip-topright"
          @click="resetEmail()"
          data-tooltip="Reset Email"
          :disabled="!enableSend">
          <span class="icon">
            <i class="mdi mdi-refresh mdi-18px" />
          </span>
          <span>Reset</span>
        </button>
        <button class="button is-primary tooltip"
          v-show="enableAttach"
          @click="openAttachModel()"
          :data-tooltip="[!toggleAttachButton ? 'Attach images' : 'Done']"
          :disabled="!enableAttach">
          <span v-if="!toggleAttachButton"
            class="icon is-small">
            <i class="mdi mdi-18px mdi-paperclip" />
          </span>
          <span v-else>Done</span>
        </button>
        <button class="button is-success tooltip is-tooltip-topright"
          @click="send()"
          data-tooltip="Send Email"
          :disabled="!enableSend">
          <span class="icon">
            <i class="mdi mdi-send mdi-18px" />
          </span>
          <span>Send</span>
        </button>
      </div>
    </div>

    <vue-email-template-modal v-if="isEmailTemplateModalActive"
      :active.sync="isEmailTemplateModalActive"
      @close="closeTemplateModal" />

  </article>
</template>

<script>
import { VueEmail } from '@/components/VueEmail'
// import mimeTypes from 'mime-types'
import { Emailer } from '@/classes'
import StoreUtil from '@/store/utils'
import _cloneDeep from 'lodash/cloneDeep'
import QuoteCommunicationsMixin from '../mixins/QuoteCommunicationsMixin'
import { RemarkTypes, AssetTypes, EmailAssetTypes } from '@/enums'
import QuoteCommunicationEmailModal from './QuoteCommunicationEmailModal.vue'
import _isEmpty from 'lodash/isEmpty'
import VueEmailTemplateModal from '@/components/VueEmail/VueEmailTemplateModal'
import { EmailService } from '@/services'
import Multiselect from 'vue-multiselect'

// Temporary const for copyright message
const divider = '=============================================================='
const copyright =
  'All accompanying materials are the exclusive property of [COMPANY].  All [COMPANY] rights are reserved.  These Materials must not be reproduced, adapted, modified, published, or communicated to any person in any form, without express prior written consent of [COMPANY].  Any unauthorised use of these Materials will be vigorously pursued and may result in legal action, including a claim for damages or an account for loss of profits.'

export default {
  name: 'QuoteEmail',
  components: { VueEmail, QuoteCommunicationEmailModal, VueEmailTemplateModal, Multiselect },
  mixins: [QuoteCommunicationsMixin],
  props: {
    quote: {
      type: Object,
      required: true
    },
    enableAttach: {
      type: Boolean,
      default: true
    },
    enableSend: {
      type: Boolean,
      default: false
    },
    toggleAttachButton: {
      type: Boolean,
      default: false
    },
    replyToEmail: {
      type: Object,
      default: function () {
        return {}
      }
    },
    selectedAttachmentEmailId: {
      type: String,
      default: ''
    },
    isIncoming: {
      type: Boolean,
      default: false
    },
    msgDetails: {
      type: Object,
      default: () => {
        return {
          quoteId: null,
          customer: null,
          rego: null,
          jobStart: null,
          jobEnd: null,
          excess: null,
          mobile: null,
          message: '',
          shopName: ''
        }
      }
    }
  },
  data: () => {
    return {
      //isEmailModalActive: false,
      //isImageAttachActive: false,
      emailer: {
        id: null,
        reportName: null,
        assetId: ''
      },
      //reportBytes: null,
      // reportContentType: '',
      selectedImages: [],
      quoteIds: [],
      isEnteringNewEmail: false,
      selectedImageIds: [],
      // replyToMessageId: '',
      replyToSenderEmails: [],
      replyToEmailCcs: [],
      replyToEmailBccs: [],
      isAttachmentModalActive: false,
      innerReplyToEmail: {},
      innerReplyToEmailSubject: '',
      innerReplyToEmailMessage: '',
      innerSelectedAttachmentEmailId: '',
      editMaximize: true,

      isEmailTemplateModalActive: false,
      isTemplateLoading: false,
      templates: [],
      messages: [],
      selectedTemplate: '',
      templateFilter: {
        message: '',
        pageIndex: 1,
        pageSize: 999
      },
      innerMsgDetails: {}
    }
  },
  computed: {
    getSender() {
      const message = this.innerReplyToEmail
      const isEmpty = this.isEmptyObject(message)
      return isEmpty ? '' : message.isIncoming ? message.sender : message.recipients
    }
    // reportExtension() {
    //   return mimeTypes.extension(this.reportContentType)
    // }
  },
  watch: {
    replyToEmail(message) {
      this.resetEmailer()
      this.clearSelectedTemplate()
      this.innerReplyToEmail = _cloneDeep(message || {})
      this.buildReplyEmail()
      //this.resetEmailer()
      //this.clearSelectedTemplate()
    },
    // replyToMessage(value) {
    //   this.replyToMessageId = value
    // },
    // replyToSender(value) {
    //   this.replyToSenderEmails = value ? [value] : []
    // },
    'emailer.attachments': {
      handler: function (newVal, oldVal) {
        if (this.selectedImages && this.selectedImages.length) {
          const differences = this.selectedImages.filter(function (image) {
            const index = newVal.findIndex((i) => i === image.fileName)
            return index === -1
          })
          if (!_isEmpty(differences)) {
            differences.forEach((diff) => {
              const imageIndex = this.selectedImages.findIndex((i) => i.fileName === diff.fileName)
              if (imageIndex !== -1) this.selectedImages.splice(imageIndex, 1)
              const idIndex = this.selectedImageIds.findIndex((id) => id === diff.quoteImageId)
              if (idIndex !== -1) this.selectedImageIds.splice(idIndex, 1)
            })
          }
        }
        // if (this.selectedImages.length > 0) {
        //   const difference = this.selectedImages.filter(function(image) {
        //     const index = newVal.findIndex(i => i === image.fileName)
        //     return index === -1
        //   })
        //   if (!_isEmpty(difference)) {
        //     const imageIndex = this.selectedImages.findIndex(i => i.fileName === difference[0].fileName)
        //     this.selectedImages.splice(imageIndex, 1)
        //     const idIndex = this.selectedImageIds.findIndex(id => id === difference[0].quoteImageId)
        //     this.selectedImageIds.splice(idIndex, 1, Guid.empty())
        //   }
        // }
      },
      deep: true
    },
    selectedAttachmentEmailId: {
      handler: function (newVal, oldVal) {
        this.innerSelectedAttachmentEmailId = newVal
        this.isAttachmentModalActive = !!newVal
      }
    }
  },

  async created() {
    this.clearSessionStorage()
    this.setEmailer()
    this.getEmailMessageDropdown()
    this.assignInnerValue()
  },
  mounted() {
    this.resetEmailer()
    this.buildReplyEmail()
  },
  beforeDestroy() {
    this.cleanEmailer()
  },
  methods: {
    assignInnerValue() {
      this.innerMsgDetails = this.msgDetails
    },
    isEmptyObject(obj) {
      // Check if obj is an object and not null
      if (typeof obj === 'object' && obj !== null) {
        // Get the internal [[Class]] property
        var objClass = Object.prototype.toString.call(obj)

        // Check if the object is an object literal or an instance of Object
        if (objClass === '[object Object]' || objClass === '[object ObjectConstructor]') {
          // Check if the object has no own enumerable properties
          return Object.keys(obj).length === 0
        }
      }

      // Return false for non-object types or non-empty objects
      return false
    },
    buildReplyEmail() {
      const isEmpty = this.isEmptyObject(this.innerReplyToEmail)
      if (!isEmpty && this.innerReplyToEmail.messageId) {
        const sender = this.getSender
        this.replyToSenderEmails = sender ? [sender] : []

        const cc = this.innerReplyToEmail.cc
        this.replyToEmailCcs = cc ? cc.split(',') : []

        const bcc = this.innerReplyToEmail.bcc
        this.replyToEmailBccs = bcc ? bcc.split(',') : []

        const currentDate = new Date()
        if (this.innerReplyToEmail.subject) {
          this.innerReplyToEmailSubject = 'Re: ' + this.innerReplyToEmail.subject
          this.emailer.subject = this.innerReplyToEmailSubject
        }

        this.innerReplyToEmailMessage = this.emailer.message + '\n\n\n\n'
        this.innerReplyToEmailMessage += `On ${currentDate}, ${this.getSender} wrote:\n\n`
        this.innerReplyToEmailMessage += this.innerReplyToEmail.message
        // if (this.getSender)
        //   this.innerReplyToEmailMessage = `${this.emailer.message} \n\n\n\nOn ${currentDate}, ${this.getSender} wrote:\n\n` + this.innerReplyToEmail.message
      } else {
        // this.resetEmailer()
        this.innerReplyToEmailSubject = this.emailer.subject
        this.innerReplyToEmailMessage = this.emailer.message
        this.replyToSenderEmails = []
        this.replyToEmailCcs = []
        this.replyToEmailBccs = []
      }
      //return message
    },
    newEmailInput(newEmail) {
      this.isEnteringNewEmail = newEmail
    },
    clearSessionStorage() {
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|${this.$route.meta.ekey}`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|${this.$route.meta.pkey}`)
    },
    setEmailer() {
      let emailer = new Emailer()
      emailer.assetId = this.quote.quoteId
      emailer.assetType = EmailAssetTypes.Quote
      emailer.assetTypes = [AssetTypes.Customer, AssetTypes.Insurer]
      emailer.remark = `Quote ${this.quote.prefix}${this.quote.quoteNo}${this.quote.suffix}`
      emailer.remarkTypeId = RemarkTypes.OtherSystemRemark
      emailer.subject = `Quote Claim No. ${this.quote.claimNo || ''}`
      emailer.reportName = `${this.quote.prefix}${this.quote.quoteNo}${this.quote.suffix}`
      this.addEmailer(emailer)
      this.emailer = _cloneDeep(this.getEmailer(emailer.id))
    },
    async resetEmailer() {
      //console.log('reset Emailer')
      let _emailer = null
      if (!_emailer) _emailer = this.retrieveEmailer()
      if (!_emailer) _emailer = StoreUtil.getLocalStorage(this.emailer.id, 'emailer')
      if (!_emailer) _emailer = _cloneDeep(this.getEmailer(this.emailer.id) || new Emailer())
      _emailer.message = `\n\n${divider}\n${copyright.replace(/\[COMPANY\]/g, this.$userInfo.companyName)}`
      this.emailer = _emailer
      this.persistEmailer()
      this.updateAttachmentList()
    },
    retrieveEmailer() {
      return JSON.parse(sessionStorage.getItem(`${this.$userInfo.sessionId}|${this.$route.meta.ekey}`))
    },
    persistEmailer() {
      sessionStorage.setItem(`${this.$userInfo.sessionId}|${this.$route.meta.ekey}`, JSON.stringify(this.emailer))
    },
    cleanEmailer() {
      //console.log('cleanEmailer')
      StoreUtil.removeStorage(this.emailer.id, 'emailer')
      StoreUtil.removeLocalStorage(this.emailer.id, 'emailer')
      this.removeEmailer(this.emailer.id)
    },
    sent() {
      this.discardEmail()
      this.$emit('sent')
    },
    resetEmail() {
      this.discardEmail()
      this.$emit('resetEmail')
    },
    updateAttachmentList() {
      this.emailer.attachments.splice(0, this.emailer.attachments.length)
      //this.emailer.attachments.splice(this.emailer.attachments.length, 1, `${this.emailer.reportName}.${this.reportExtension}`)
      //console.log('updateAttachmentList')
      //console.log(this.selectedImages)
      for (var i = 0; i < this.selectedImages.length; i++) {
        this.emailer.attachments.push(this.selectedImages[i].fileName)
      }
    },
    // async resetReportParameters() {
    //   const sessionParameters = this.retrieveParameters() || StoreUtil.getLocalStorage(this.$route.query.parameterId, this.$route.meta.pkey)
    //   // console.log('sessionParameters', sessionParameters)
    //   if (sessionParameters) {
    //     this.parameters = sessionParameters
    //   } else {
    //     this.parameters = _cloneDeep(this.getParameters)
    //   }
    //   // console.log(this.parameters, this.getParameters)
    //   StoreUtil.removeLocalStorage(this.$route.query.parameterId, this.$route.meta.pkey)
    //   this.persistParameters()
    // },
    // retrieveParameters() {
    //   const json = sessionStorage.getItem(`${this.$userInfo.sessionId}|${this.$route.meta.pkey}`)
    //   return json ? JSON.parse(json) : ''
    // },
    // persistParameters() {
    //   sessionStorage.setItem(`${this.$userInfo.sessionId}|${this.$route.meta.pkey}`, JSON.stringify(this.parameters))
    // },
    async send() {
      // this.isActive = false
      // this.$emit('update:active', false)
      await this.$refs.emailer.send()
      this.clearSelectedTemplate()
    },
    discardEmail() {
      //this.isImageAttachActive = false
      this.selectedImageIds.splice(0, this.selectedImageIds.length)
      this.selectedImages.splice(0, this.selectedImages.length)
      // this.replyToMessageId = ''
      this.replyToSenderEmails = []
      this.replyToEmailCcs = []
      this.replyToEmailBccs = []
      this.resetEmailer()
    },
    openAttachModel() {
      this.isAttachmentModalActive = true
    },
    closeAttachModal() {
      this.isAttachmentModalActive = false
      this.innerSelectedAttachmentEmailId = ''
      this.updateAttachmentList()
      this.$emit('close')
    },
    toggleEditMaximize() {
      this.editMaximize = !this.editMaximize
      this.$emit('toggleEditMaximize')
    },
    async getEmailMessageDropdown() {
      this.isTemplateLoading = true
      const postData = { ...this.templateFilter, ...{ message: '' } }
      const templates = await EmailService.getEmailTemplateDropdown(postData)
      this.templates = templates
      if (this.templateFilter.message !== '') {
        this.selectedTemplate = this.templates.find((c) => c.message === this.templateFilter.message)
        if (!this.selectedTemplate) {
          this.clearSelectedTemplate()
          const templates = await EmailService.getEmailTemplateDropdown(this.templateFilter)
          this.templates = templates
        }
      }
      this.isTemplateLoading = false
    },
    async closeTemplateModal() {
      this.isEmailTemplateModalActive = false
      //console.log('close template modal')
      setTimeout(async () => await this.getEmailMessageDropdown(), 1000)
    },
    selectTemplate(selected) {
      //this.innerMsgDetails.customer = this.extractName(this.getSender)
      this.resetEmailer()
      this.clearSelectedTemplate()
      this.buildReplyEmail()
      this.templateFilter.message = selected.message
      this.selectedTemplate = selected
      //console.log(this.emailer.message)
      const templateMessage = this.replaceTags(selected.message)
      this.innerReplyToEmailMessage = templateMessage + '\n\n' + this.innerReplyToEmailMessage
    },
    clearSelectedTemplate() {
      this.templateFilter.message = ''
      //this.innerReplyToEmail.message = ''
      //this.innerMsgDetails.customer = this.msgDetails.customer
      this.selectedTemplate = ''
    },
    replaceTags(message) {
      message = message.replace(/\[QUOTEID\]/g, this.innerMsgDetails.quoteId)
      message = message.replace(/\[NAME\]/g, this.innerMsgDetails.customer)
      message = message.replace(/\[REGO\]/g, this.innerMsgDetails.rego)
      message = message.replace(/\[EST START DATE\]/g, this.innerMsgDetails.jobStart)
      message = message.replace(/\[EST END DATE\]/g, this.innerMsgDetails.jobEnd)
      message = message.replace(/\[EXCESS\]/g, this.innerMsgDetails.excess)
      message = message.replace(/\[SHOPNAME\]/g, this.innerMsgDetails.shopName)
      return message
    },
    extractName(sender) {
      if (sender) {
        let matchName = sender.match(/^[^<]*/)
        if (matchName) return matchName[0].trim()
        let matchEmail = sender.match(/<([^>]+)>/)
        if (matchEmail) return matchEmail[1]
      }
      return ''
    },
    resetReplyToEmail() {
      this.innerReplyToEmail = {}
      this.innerReplyToEmailSubject = ''
      this.innerReplyToEmailMessage = ''
      // this.replyToMessageId = ''
      this.replyToSenderEmails = []
      this.replyToEmailCcs = []
      this.replyToEmailBccs = []
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons-group {
  justify-content: flex-end;
}

.buttons-group > .button:not(:last-child) {
  margin-right: 0.5em;
}
</style>
